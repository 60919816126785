/**
 * Browser API Module
 *
 * Handles common UI interactions that rely on the browser.
 * - Redirects
 * - URL Params
 * - Form enter key events
 */
// import fetch from 'cross-fetch';
import queryString from 'query-string';

// Page load timeout function, cb function should return true to redirect to 404
const pageTimeout = (cb) => {
  const defaultTimeout = 2 * 60 * 1000    // 2 minutes
  setTimeout( () => {
    if(cb())
      redirectToPage('404')
    // } else {
    //   console.log('we good.')
    // }
  }, defaultTimeout)
}

// Do a redirect to a page on same origin/domain withh optional slug
const redirectToPage = (page, queryObj = null) => {
  // console.log({ page, queryObj })
  let newLoc = window.location.origin + '/' + page;
  newLoc += queryObj ? '?' + stringify(queryObj) : '';
  doRedirect(newLoc);
};

// Open a new browser tab
const newBrowserTab = (url) => {
  window.open(url);
};

// Get URL params
const getUrlParams = () => {
  // console.log(window.location.href)
  const params = queryString.parse(window.location.search);
  return params;
};

// Do a redirect
const doRedirect = (newLoc) => {
  window.location.href = newLoc;
};

// Do a page refresh
const doReload = () => {
  window.location.reload(true);
};

// Returns a url stringified slug from an object
const stringify = (obj) => {
  return queryString.stringify(obj);
};

// Handle enter key on user input
const handleEnterKey = (e, cb) => {
  if (e.key === 'Enter') {
    cb();
  }
};

// Copy html element text
const copyElementText = (containerid) => {
  if (document.selection) {
    var range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select().createTextRange();
    document.execCommand('copy');
  } else if (window.getSelection) {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().addRange(range);
    document.execCommand('copy');
    // alert("Text has been copied, now paste in the text-area")
  }
};

export {
  pageTimeout,
  redirectToPage,
  newBrowserTab,
  getUrlParams,
  doRedirect,
  doReload,
  stringify,
  handleEnterKey,
  copyElementText,
};
